import React, { useEffect, useState } from "react";
import qs from "qs";
import {
  ExclamationCircleOutlined,
  SwapOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { INVENTORY_INFO, DEVICE_ARR, distint } from "../../../config/constant";
import { Form, Modal, Switch, Row, Col, message, Button, Tooltip } from "antd";
import {
  progressApi,
  userDisabled,
  userInfoApi,
  userNickNameApi,
  channelApi,
} from "../../../api/api";
import moment from "moment";
import { useNavigate, useLocation } from "react-router-dom";
import "./Detail.scss";
const { confirm } = Modal;
export default function Detail() {
  const navigate = useNavigate();
  const location = useLocation();
  const [userInfo, setUserInfo] = useState({});
  const [userId, setUserId] = useState();
  const [channel, setChannel] = useState();
  const [userStatus, setUserStatus] = useState(false);
  const [channelObj, setChannelObj] = useState({});
  const [progress, setProgress] = useState({});
  const params = qs.parse(location.search.replace(/^\?/, ""));
  const getDisabled = () => {
    axios
      .post(userDisabled, {
        token: localStorage.getItem("token"),
        user_id: userId,
      })
      .then((res) => {
        if (res.data && res.data.err === 0) {
          getUserInfo(userId, channel);
        } else {
          message.error("获取列表失败");
        }
      });
  };
  const getUserInfo = (userId, channel) => {
    axios
      .post(userInfoApi, {
        token: localStorage.getItem("token"),
        user_id: userId,
        channel,
      })
      .then((res) => {
        if (res.data && res.data.err === 0) {
          setUserInfo(res.data.data.user);
          setUserStatus(res.data.data.user.disable === 0);
        } else {
          message.error("获取列表失败");
        }
      });
  };
  const getProgress = (userId, channel) => {
    axios.post(progressApi, {
      token: localStorage.getItem("token"),
      user_id: userId,
      channel,
    })
    .then((res) => {
      if (res.data && res.data.err === 0) {
        setProgress(res.data.data.progress);
      } else {
        message.error("获取进度信息失败");
      }
    });
  };
  const getChannelList = async () => {
    let newArr = [];
    let newObj = {};
    // 获取用户渠道下拉值
    await axios
      .post(channelApi, { token: localStorage.getItem("token") })
      .then((res) => {
        if (res.data && res.data.err === 0) {
          DEVICE_ARR.forEach((item) => {
            newArr.push(...res.data.data.channel[item]);
          });
          // 渠道去重
          newArr = distint(newArr);
          newArr.forEach((item) => {
            newObj[item.channel] = item.label;
          });
          setChannelObj(newObj);
        } else {
          message.error("获取渠道信息失败");
        }
      });
  };
  const changeSwitch = (e) => {
    if (userInfo.disable === 0) {
      setUserStatus(false);
      confirm({
        title: "账号封禁",
        icon: <ExclamationCircleOutlined />,
        content: "确认封禁当前账号吗？封禁后,该用户将无法登录游戏",
        okText: "封禁",
        cancelText: "取消",
        onOk() {
          getDisabled();
        },
        onCancel() {
          setUserStatus(true);
        },
      });
    }
  };
  const updateNickName = () => {
    axios
      .post(userNickNameApi, {
        token: localStorage.getItem("token"),
        user_id: userId,
      })
      .then((res) => {
        if (res.data && res.data.err === 0) {
          message.success("昵称修改成功");
          getUserInfo(userId, channel);
        } else {
          message("修改昵称失败");
        }
      });
  };
  useEffect(() => {
    getChannelList();
    if (params.userId && params.channel) {
      const userId = Number(params.userId);
      setChannel(params.channel);
      // 调取当前用户详情信息接口
      getUserInfo(userId, params.channel);
      getProgress(userId, params.channel);
      setUserId(userId);
    }
  }, []);
  const changeName = (name) => {
    confirm({
      title: "修改用户名称",
      icon: <QuestionCircleOutlined />,
      content: `确认修改用户昵称“${name}”吗？`,
      okText: "确认",
      cancelText: "取消",
      onOk() {
        updateNickName();
      },
    });
  };
  const lookDetail = (item, channel) => {
    navigate(
      `/production?user_id=${params.userId}&sku_type=${item}&channel=${channel}`
    );
  };
  const handleChapter = (data) => {
    let newata = {};
    let chapList = [];
    Object.keys(data).forEach((item) => {
      if (item.indexOf("chap") > -1) {
        newata["chapter"] = false;
        if (item === 1) {
          newata["chapter"] = true;
        }
      } else {
        // 处理章节外的数据并加单位
        if (item === "egg") {
          newata[item] = data[item] + "个";
        } else if (item === "currency") {
          newata[item] = data[item] + "枚";
        } else if (item === "flower") {
          newata[item] = data[item] + "支";
        } else if (item === "free") {
          newata[item] = data[item];
        } else if (item === "gratitude") {
          newata[item] = data[item];
        } else if (item === "fight") {
          newata[item] = data[item];
        } else if (item === "full") {
          newata[item] = data[item];
        }
      }
      return newata;
    });
    return newata;
  };
  return (
    <div>
      <div className="userInfo">
        <div className="topTitle">用户信息</div>
        <Form className="form">
          <Row>
            <Col span={1} />
            <Form.Item name="channel" label="用户登录渠道">
              <div className="right">
                {channelObj[userInfo.channel]
                  ? channelObj[userInfo.channel]
                  : userInfo.channel}
              </div>
            </Form.Item>
          </Row>
          <Row>
            <Col span={1} />
            <Form.Item name="user_id" label="用户数字ID">
              <div className="right">{userInfo.user_id}</div>
            </Form.Item>
          </Row>
          <Row>
            <Col span={1} />
            <Form.Item name="alt_id" label="用户ID">
              <div className="right">{userInfo.alt_id}</div>
            </Form.Item>
          </Row>
          <Row>
            <Col span={1} />
            <Col span={23}>
              <Form.Item name="nick_name" label="用户昵称">
                <div className="right">{userInfo.nick_name}</div>
                <div className="right">
                  <Tooltip placement="topLeft" title="替换昵称">
                    <Button type="link">
                      <SwapOutlined
                        onClick={() => changeName(userInfo.nick_name)}
                        style={{ fontSize: "20px" }}
                      />
                    </Button>
                  </Tooltip>
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={1} />
            <Form.Item name="time" label="注册时间">
              <div className="right">
                {moment(userInfo.created_at).format("YYYY-MM-DD HH:mm:ss")}
              </div>
            </Form.Item>
          </Row>
          <Row>
            <Col span={1} />
            <Form.Item name="disable" label="用户状态">
              <div className="right">
                <Switch
                  checked={userStatus}
                  checkedChildren="正常"
                  unCheckedChildren="封禁"
                  onChange={() => changeSwitch(userInfo.disable)}
                  disabled={userInfo.disable === 1}
                />
              </div>
            </Form.Item>
          </Row>
        </Form>
      </div>


      <div className="prodInfo">
        <div className="topTitle">库存信息</div>
        <div className="allProd">
          {userInfo.items &&
            Object.keys(handleChapter(userInfo.items)).map((item) => {
              return (
                <div className="square" key={item}>
                  {item !== "chapter" ? (
                    ["flower", "egg", "gameCurrency"].includes(item) ? (
                      <>
                        <div>
                          {INVENTORY_INFO[item]}
                          {handleChapter(userInfo.items)[item]}
                        </div>
                        <Button
                          type="link"
                          onClick={() => lookDetail(item, userInfo.channel)}
                          className="detail"
                        >
                          点击查看详情
                        </Button>
                      </>
                    ) : (
                      <>
                        <div>{INVENTORY_INFO[item]}</div>
                        <Button
                          type="link"
                          onClick={() =>
                            lookDetail("chapter", userInfo.channel)
                          }
                          className="detail"
                        >
                          点击查看详情
                        </Button>
                      </>
                    )
                  ) : (
                    <>
                      <div>
                        {INVENTORY_INFO[item]}
                        {handleChapter(userInfo.items)[item] === true
                          ? "（部分）"
                          : "（全部）"}
                      </div>
                      <Button
                        type="link"
                        onClick={() => lookDetail(item, userInfo.channel)}
                        className="detail"
                      >
                        点击查看详情
                      </Button>
                    </>
                  )}
                </div>
              );
            })}
        </div>
      </div>

      <div className="progressBoard">
        <div className="topTitle">用户进度</div>
        <div className="allProd">
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th style={{ padding: '12px', borderBottom: '1px solid #bbb' }}>章节</th>
                <th style={{ padding: '12px', borderBottom: '1px solid #bbb' }}>进度</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(progress).map(([chap_id, percentage], index) => {
                return (
                  <tr key={index}>
                    <td style={{ padding: '12px', borderBottom: '1px solid #eee', textAlign: 'center' }}>{chap_id}</td>
                    <td style={{ padding: '12px', borderBottom: '1px solid #eee', textAlign: 'center' }}>
                    {`${percentage}%`}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
