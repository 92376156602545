const rootURL = process.env.REACT_APP_BASE_URL;
const VERSION = `/v1`;
console.log({ rootURL, VERSION });
// module.exports = {
// 注册
export const loginApi = `${rootURL}${VERSION}/admin/login`;
// 退出登录
export const layoutApi = `${rootURL}${VERSION}/admin/logout`;
// 用户列表
export const userListApi = `${rootURL}${VERSION}/user/list`;
// 用户信息
export const userInfoApi = `${rootURL}${VERSION}/user/info`;
// 修改违规昵称
export const userNickNameApi = `${rootURL}${VERSION}/user/nickname`;
// 禁用用户
export const userDisabled = `${rootURL}${VERSION}/user/disabled`;

export const progressApi = `${rootURL}${VERSION}/progress/get`;
// 补单
export const supplementApi = `${rootURL}${VERSION}/order/supplement`;
// 用户无单补单
export const pureSupplementSubmitApi = `${rootURL}${VERSION}/order/pure_supplement`;
// 无单补单列表
export const noBillSupplementListApi = `${rootURL}${VERSION}/user/supplementList`;
// 用户商品类型列表
export const goodslistApi = `${rootURL}${VERSION}/user/goodsType`;
// 库存列表
export const inventoryListApi = `${rootURL}${VERSION}/inventory/list`;
// 订单列表
export const orderListApi = `${rootURL}${VERSION}/order/list`;
// 订单详情，用于补单
export const orderGetApi = `${rootURL}${VERSION}/order/get`;
// 创建激活码
export const cdkGenerateApi = `${rootURL}${VERSION}/cdkey/generate`;
// 激活码列表
export const cdkeyListApi = `${rootURL}${VERSION}/cdkey/list`;
// 激活码详情
export const cdkDetailApi = `${rootURL}${VERSION}/cdkey/detail`;
//  激活码禁用
export const cdkBanApi = `${rootURL}${VERSION}/cdkey/ban`;
// 留言本相关
// 列举留言
export const commentListApi = `${rootURL}${VERSION}/comment/list`;
// 审批留言
export const commentApproveApi = `${rootURL}${VERSION}/comment/approve`;
// 伪造水军留言
export const commentForgeApi = `${rootURL}${VERSION}/comment/forge`;
// 为留言修正互动表情包
export const commentReactionApi = `${rootURL}${VERSION}/comment/reaction`;
// 获取单个留言
export const commentGetApi = `${rootURL}${VERSION}/comment/get`;
// 主播相关
// 列举主播
export const streamerListApi = `${rootURL}${VERSION}/streamer/list`;
// 创建主播
export const streamerCreateApi = `${rootURL}${VERSION}/streamer/create`;
// 编辑主播
export const streamerEditApi = `${rootURL}${VERSION}/streamer/edit`;
// 获取单个主播
export const streamerGetApi = `${rootURL}${VERSION}/streamer/get`;
// 屏蔽主播与解除屏蔽
export const streamerBlockApi = `${rootURL}${VERSION}/streamer/block`;

//渠道信息
export const channelApi = `${rootURL}${VERSION}/system/channel`;

//ivf版本号列表
export const appIvfListApi = `${rootURL}${VERSION}/app/ivf/list`;
// ivf版本号列表（app版本创建时下拉框数据)
export const appIvfEditionsApi = `${rootURL}${VERSION}/app/ivf/editions`;

// ivf单个版本信息
export const appIvfGetApi = `${rootURL}${VERSION}/app/ivf/get`;
//ivf版本创建
export const appIvfCreateApi = `${rootURL}${VERSION}/app/ivf/create`;
// ivf版本更新
export const appIvfUpdateApi = `${rootURL}${VERSION}/app/ivf/update`;
// 禁用启用或禁用IVF版本
export const appIvfEnableApi = `${rootURL}${VERSION}/app/ivf/enable`;

// app版本创建
export const appVersionCreateApi = `${rootURL}${VERSION}/app/appversion/create`;
// app版本更新
export const appVersionUpdateApi = `${rootURL}${VERSION}/app/appversion/update`;
// app列表
export const appVersionListApi = `${rootURL}${VERSION}/app/appversion/list`;
// app详情
export const appVersionGet = `${rootURL}${VERSION}/app/appversion/get`;

///app/appversion/list_publish、列出待发布生效app版本列表
export const appAppversionPublicApi = `${rootURL}${VERSION}/app/appversion/list_cards`;
// 生效页面一键发布
export const appversionPublishApi = `${rootURL}${VERSION}/app/appversion/publish`;
// 不同渠道app的下拉值
export const appversionListVersionOnlyApi = `${rootURL}${VERSION}/app/appversion/list_version_only`;
